<template>
    <div class="level-item has-text-centered">
        <div>
            <p class="heading">No hay documentos disponibles</p>
            <p class="title">
                <img
                    :src="require('@/assets/svg/045-file.svg')"
                    style="max-width: 20%"
                    alt="Imagen"
                />
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
