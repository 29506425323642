<template>
    <div class="is-relative">
        <b-loading :is-full-page="false" v-model="loading" />
        <div class="box" v-if="documentos.length === 0">
            <no-documentos />
        </div>
        <transition-group name="flip-list" tag="div">
            <div
                v-for="grupoDocumento of gruposDocumentos"
                :key="grupoDocumento.estado"
            >
                <p class="mb-2 has-text-weight-semibold is-uppercase">
                    {{ grupoDocumento.estado }} ({{
                        grupoDocumento.documentos.length
                    }})
                </p>
                <transition-group name="flip-list" tag="ul">
                    <li
                        v-for="documento of grupoDocumento.documentos"
                        :key="documento.id"
                    >
                        <a
                            class="
                                box
                                document-box
                                is-flex
                                is-align-items-center
                                is-justify-content-space-between
                            "
                            :class="[
                                userDocumentListItemClass(
                                    documento,
                                    grupoDocumento
                                ),
                                selectedDocument.id === documento.id &&
                                    'selected',
                            ]"
                            @click.prevent="
                                !documento.restringido
                                    ? selectDocument(documento)
                                    : documentLockedAlert()
                            "
                        >
                            <div class="is-flex is-align-items-center">
                                <b-icon
                                    :icon="
                                        userDocumentListItemIcon(
                                            documento,
                                            grupoDocumento
                                        )
                                    "
                                    size="is-medium"
                                />
                                <div class="ml-3">
                                    <p
                                        class="
                                            document-box__periodo
                                            has-text-primary
                                        "
                                    >
                                        {{ documento.periodo | moment }}
                                    </p>
                                    <p class="document-box__tipo">
                                        {{
                                            documento.tipo_documento.descripcion
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div class="arrow-selected">
                                <b-icon icon="chevron-right" size="is-medium" />
                            </div>
                        </a>
                    </li>
                </transition-group>
                <br />
            </div>
        </transition-group>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment';
import Message from '@/utils/Message';
import Utils from '@/utils/Utils';

import NoDocumentos from '@/components/Documentos/NoDocumentos.vue';

export default {
    name: 'UserDocumentsList',
    props: {
        documentos: Array,
        loading: Boolean,
    },
    components: {
        NoDocumentos,
    },
    computed: {
        gruposDocumentos() {
            const estados = Array.from(
                new Set(
                    this.documentos.map((documento) => {
                        return documento.estado.descripcion;
                    })
                )
            );

            const gruposDocumentos = estados.map((estado) => {
                return {
                    estado,
                    documentos: this.documentos.filter((documento) => {
                        return documento.estado.descripcion === estado;
                    }),
                };
            });

            return gruposDocumentos;
        },
        selectedDocument() {
            return this.$store.state.documentos.selected;
        },
        isMobile() {
            return Utils.mobileCheck();
        },
    },
    methods: {
        userDocumentListItemClass(documento, grupoDocumento) {
            return !documento.restringido
                ? grupoDocumento.estado === 'FIRMADO CONFORME'
                    ? 'signed'
                    : grupoDocumento.estado === 'NO FIRMADO'
                    ? 'not-signed'
                    : 'not-required-sign'
                : 'locked';
        },
        userDocumentListItemIcon(documento, grupoDocumento) {
            return !documento.restringido
                ? grupoDocumento.estado === 'FIRMADO CONFORME'
                    ? 'text-box-check-outline'
                    : grupoDocumento.estado === 'NO FIRMADO'
                    ? 'text-box'
                    : 'text-box'
                : 'lock';
        },
        documentLockedAlert() {
            Swal.fire({
                title: 'Documento restringido',
                text: 'Tiene documentos pendientes, debe firmarlos para poder visualizar este documento',
                icon: 'warning',
            });
        },
        async selectDocument(document) {
            if (!this.isMobile && this.selectedDocument.id == document.id) {
                return;
            }

            if (this.isMobile) {
                this.$router.push({
                    name: 'user-documentos-visualizador',
                    params: { id: document.id },
                });
                return;
            }

            this.$store.dispatch('documentos/setLoading', true);
            try {
                this.$store.dispatch('documentos/selectDocumento', document);
                await this.$store.dispatch('documentos/view', document.id);
            } catch (err) {
                console.log(err);
                this.$store.dispatch('documentos/selectDocumento', {});
                Message.showErrorMessage(
                    this,
                    `Error al obtener documento: ${err.message}`
                );
            } finally {
                this.$store.dispatch('documentos/setLoading', false);
            }
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('MM/YYYY');
        },
    },
};
</script>

<style lang="scss">
.document-box {
    border-radius: 4px;
    border-style: solid;
    border-width: 0 0 0 4px;

    &:hover {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
            0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
        background-color: whitesmoke;
    }

    &.selected {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
            0 0px 0 1px rgba(10, 10, 10, 0.02) !important;
        background-color: #f0f0f0;
        cursor: default;
    }

    &.not-signed {
        border-color: #f14668;

        span > i {
            color: #f14668;
        }
    }

    &.signed {
        border-color: #54caa6;

        span > i {
            color: #54caa6;
        }
    }

    &.locked {
        border-color: darkgray;

        span > i {
            color: darkgray;
        }
    }

    &.not-required-sign {
        border-color: #54a4ca;

        span > i {
            color: #54a4ca;
        }
    }

    &__periodo {
        font-size: 1rem;
    }

    &__tipo {
        font-size: 0.75rem;
        color: darkslategrey;
    }

    & > .arrow-selected {
        display: none;
    }

    &:hover > .arrow-selected {
        display: block;
    }

    &.selected > .arrow-selected {
        display: block;
    }
}
</style>
