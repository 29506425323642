<template>
    <div class="card mb-4">
        <div class="card-content">
            <b-field label="Búsqueda por período" label-position="on-border">
                <b-datepicker
                    type="month"
                    placeholder="Seleccione un periodo"
                    icon="calendar-today"
                    v-model="form.periodos"
                    range
                    :mobile-native="false"
                />
            </b-field>
            <br />
            <b-field label="Tipo de Documento" label-position="on-border">
                <!-- <b-taginput
                    v-model="form.tiposDocumentos"
                    :data="filteredTags"
                    autocomplete
                    open-on-focus
                    attached
                    field="descripcion"
                    icon="label"
                    placeholder="Agregar un tipo de documento"
                    type="is-primary is-light"
                    @typing="getFilteredTags"
                >
                </b-taginput> -->

                <b-taginput
                    v-model="form.tiposDocumentos"
                    :data="filteredTags"
                    autocomplete
                    open-on-focus
                    attached
                    field="descripcion"
                    icon="label"
                    type="is-primary is-light"
                    @typing="getFilteredTags"
                >
                </b-taginput>
            </b-field>
        </div>
        <footer class="card-footer">
            <b-button
                class="card-footer-item"
                type="is-primary"
                icon-left="delete"
                outlined
                @click="handleLimpiarFiltros"
            >
                Limpiar
            </b-button>
            <b-button
                class="card-footer-item"
                type="is-primary"
                icon-left="filter-variant"
                @click="handleAplicarFiltros"
            >
                Aplicar
            </b-button>
        </footer>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'FiltrosDocumentos',
    data() {
        return {
            filteredTags: [],
            form: {
                periodos: [],
                tiposDocumentos: []
            }
        };
    },
    computed: {
        tiposDocumentos() {
            return this.$store.state.tiposDocumentos.data;
        },
    },
    async mounted() {
        if (this.tiposDocumentos.length === 0) {
            await this.$store.dispatch('tiposDocumentos/get');
        }

        this.getFilteredTags();
    },
    methods: {
        getFilteredTags(text='') {
            this.filteredTags = this.tiposDocumentos.filter(option => {
                return (
                    option.descripcion
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                );
            });
        },
        async handleAplicarFiltros() {
            const filters = {
                periodos: this.form.periodos.map(periodo => moment(periodo).format('YYYY-MM-DD').toString()),
                tipos_documentos: this.form.tiposDocumentos.map(tipoDocumento => tipoDocumento.descripcion)
            };
            await this.$store.dispatch('documentos/getByPerfil', filters);
        },
        async handleLimpiarFiltros() {
            this.form.periodos = [];
            this.form.tiposDocumentos = [];

            await this.$store.dispatch('documentos/getByPerfil');
        },
    }
};
</script>

<style></style>
