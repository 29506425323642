<template>
    <div class="mx-5">
        <div class="columns">
            <div
                class="column is-one-third p-5"
                style="overflow: auto; max-height: 90vh"
            >
                <!-- Filtro documents title -->
                <div
                    class="
                        is-flex
                        mb-3
                        is-align-items-center is-justify-content-space-between
                    "
                >
                    <div class="title is-6 mb-0">Documentos</div>
                    <div class="is-flex is-align-items-center">
                        <b-button
                            type="is-text"
                            icon-right="menu-down"
                            @click="viewFilters = !viewFilters"
                        >
                            Filtros
                        </b-button>
                    </div>
                </div>
                <!-- Filtros documentos -->
                <transition name="slide-fade">
                    <filtros-documentos v-show="viewFilters" />
                </transition>
                <!-- Lista de documentos -->
                <user-documents-list
                    :documentos="documentos"
                    :loading="loadingDocumentos"
                />
            </div>
            <div v-if="!isMobile" class="column p-5">
                <!-- Mostrar PDF -->
                <previsualizar-pdf />
            </div>
        </div>
    </div>
</template>

<script>
import UserDocumentsList from '@/components/Documentos/UserDocumentsList.vue';
import FiltrosDocumentos from '@/components/Documentos/FiltrosDocumentos.vue';
import PrevisualizarPdf from '@/components/Documentos/PrevisualizarPdf.vue';
import NoDocumentos from '@/components/Documentos/NoDocumentos.vue';
import Utils from '@/utils/Utils';
import Message from '@/utils/Message';

export default {
    name: 'UserDocuments',
    metaInfo: {
        title: 'Mis documentos',
    },
    components: {
        UserDocumentsList,
        FiltrosDocumentos,
        PrevisualizarPdf,
        NoDocumentos,
    },
    data() {
        return {
            viewFilters: false,
        };
    },
    computed: {
        documentos() {
            return this.$store.state.documentos.data;
        },
        documentosVisibles() {
            return this.documentos.filter(
                (documento) => !documento.restringido
            );
        },
        loadingDocumentos() {
            return this.$store.state.documentos.isLoadingGetByPerfil;
        },
        isMobile() {
            return Utils.mobileCheck();
        },
    },
    async mounted() {
        await this.fetchDocumentos();
        if (this.documentosVisibles.length > 0) {
            await this.selectDocument(this.documentosVisibles[0]);
        }
    },
    methods: {
        async fetchDocumentos() {
            await this.$store.dispatch('documentos/getByPerfil');
        },
        async selectDocument(document) {
            this.$store.dispatch('documentos/setLoading', true);
            try {
                this.$store.dispatch('documentos/selectDocumento', document);
                await this.$store.dispatch('documentos/view', document.id);
            } catch (err) {
                console.log(err);
                this.$store.dispatch('documentos/selectDocumento', {});
                Message.showErrorMessage(
                    this,
                    `Error al obtener documento: ${err.message}`
                );
            } finally {
                this.$store.dispatch('documentos/setLoading', false);
            }
        },
    },
};
</script>

<style>
</style>
