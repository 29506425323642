var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-relative"},[_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),(_vm.documentos.length === 0)?_c('div',{staticClass:"box"},[_c('no-documentos')],1):_vm._e(),_c('transition-group',{attrs:{"name":"flip-list","tag":"div"}},_vm._l((_vm.gruposDocumentos),function(grupoDocumento){return _c('div',{key:grupoDocumento.estado},[_c('p',{staticClass:"mb-2 has-text-weight-semibold is-uppercase"},[_vm._v(" "+_vm._s(grupoDocumento.estado)+" ("+_vm._s(grupoDocumento.documentos.length)+") ")]),_c('transition-group',{attrs:{"name":"flip-list","tag":"ul"}},_vm._l((grupoDocumento.documentos),function(documento){return _c('li',{key:documento.id},[_c('a',{staticClass:"\n                            box\n                            document-box\n                            is-flex\n                            is-align-items-center\n                            is-justify-content-space-between\n                        ",class:[
                            _vm.userDocumentListItemClass(
                                documento,
                                grupoDocumento
                            ),
                            _vm.selectedDocument.id === documento.id &&
                                'selected' ],on:{"click":function($event){$event.preventDefault();!documento.restringido
                                ? _vm.selectDocument(documento)
                                : _vm.documentLockedAlert()}}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{attrs:{"icon":_vm.userDocumentListItemIcon(
                                        documento,
                                        grupoDocumento
                                    ),"size":"is-medium"}}),_c('div',{staticClass:"ml-3"},[_c('p',{staticClass:"\n                                        document-box__periodo\n                                        has-text-primary\n                                    "},[_vm._v(" "+_vm._s(_vm._f("moment")(documento.periodo))+" ")]),_c('p',{staticClass:"document-box__tipo"},[_vm._v(" "+_vm._s(documento.tipo_documento.descripcion)+" ")])])],1),_c('div',{staticClass:"arrow-selected"},[_c('b-icon',{attrs:{"icon":"chevron-right","size":"is-medium"}})],1)])])}),0),_c('br')],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }